<template>
    <div class="wizard-general">
        <div class="wizard-general__title wizard__title">
            General information
        </div>
        <general-info-form ref="form">
            <template #buttons>
                <div class="wizard-content__buttons">
                    <custom-button
                        default
                        class="button btn-base_colored sort-button"
                        @on-btn-click="$emit('goToPrevStep', $event)"
                    >
                        Previous
                    </custom-button>
                    <custom-button
                        default
                        @on-btn-click="confirm"
                    >
                        Next
                    </custom-button>
                </div>
            </template>
        </general-info-form>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus'

import GeneralInfoForm from '@/components/GeneralInfoForm.vue'

export default {
    name: 'WizardGeneral',
    components: {
        GeneralInfoForm
    },
    metaInfo: {
        title: 'Wizard General'
    },
    methods: {
        async confirm(e) {
            e.preventDefault()
            try {
                await this.$refs['form'].confirm()
                this.$emit('goToNextStep')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
    .wizard-general {
        &__field {
            margin-bottom: 20px;
            .form-select__input {
                padding-left: 10px;
            }
        }
    }
</style>
